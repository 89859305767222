<template>
  <div>
    <b-card
      no-body
      class="p-2 my-2"
      v-for="(table, index) in tables"
      :key="index"
    >
      <strong class="d-block mb-2">{{ table.name }}</strong>
      <div class="d-flex w-100 justify-content-between px-1 bg-light">
        <div style="min-width: 120px">
          <strong class="d-block">Mínimo</strong>
          <strong class="d-block">Máximo</strong>
          <strong class="d-block">Pontos</strong>
        </div>
        <div style="width: 170px" v-for="(range, i) in table.ranges" :key="i">
          <span class="d-block text-center">{{ range.min }}</span>
          <span class="d-block text-center">{{ range.max }}</span>
          <i class="d-block text-center text-muted">
            {{ range.value }}
          </i>
        </div>
      </div>
      <hr class="m-0" />
      <div class="d-flex w-100 justify-content-between px-1 bg-light">
        <div style="width: 90px">
          <strong class="d-block">Resultado</strong>
        </div>
        <div v-for="(ranger, id) in table.ranges" :key="`-${id}`">
          <strong
            class="d-block text-center text-muted text-primary"
            v-if="ranger.applied"
          >
            {{ table.result.toFixed(2) }}
          </strong>
          <div v-else></div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    return {
      tables: [],
      items: [],
      fields: [
        {
          key: "weight",
          label: "Peso",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.tables = this.data.indicators;
  },
};
</script>