<template>
  <div class="fundo text-center mt-2">
    <div id="fundotercoper" class="d-block mx-auto mb-5">
      <div id="gaugeArea"></div>

      <div class="fundotercoper">
        <div class="cilinder">
          <div class="step step1">
            <strong class="badge" v-if="grade <= 2" style="color: white">
              Não recomendado
            </strong>
          </div>
          <div class="step step2">
            <strong
              class="badge"
              v-if="grade > 2 && grade <= 4"
              style="color: white"
            >
              Risco alto
            </strong>
          </div>
          <div class="step step3">
            <strong class="badge" v-if="grade > 4 && grade <= 6">
              Risco médio
            </strong>
          </div>
          <div class="step step4">
            <strong class="badge" v-if="grade > 6 && grade <= 8">
              Risco baixo
            </strong>
          </div>
          <div class="step step5">
            <strong class="badge" v-if="grade > 8 && grade <= 10">
              Risco irrelevante
            </strong>
          </div>
        </div>
      </div>
      <h5 class="mt-3 text-center">Peso: {{ weight }}%</h5>
    </div>
    <div id="table" class="w-100 d-block">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="mb-2" v-b-toggle.modal-ranges>
          Visualizar faixa de pontuações
        </b-button>
      </div>
      <b-table striped small :fields="fields" :items="items" responsive>
        <template v-slot:custom-foot>
          <tr>
            <th>Total</th>
            <th>{{ weight }}</th>
            <th></th>
            <th></th>
            <th>{{ grade }}</th>
          </tr>
        </template>
      </b-table>
    </div>
    <b-sidebar
      id="modal-ranges"
      size="lg"
      left
      align="left"
      shadow
      backdrop
      backdrop-variant="transparent"
      :width="is_mobile ? '86%' : '33%'"
    >
      <template #header> <h5>Pontuações deste módulo</h5></template>

      <modal-fundotercoper :data="data" v-if="!!data" />
      <template #modal-footer="{ close }">
        <b-button variant="primary" @click="close()">Fechar</b-button>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import ModalFundotercoper from "./ModalFundotercoper";
import { api } from "@/services/api";
import { mapGetters } from "vuex";
const GaugeChart = require("gauge-chart");

export default {
  components: {
    ModalFundotercoper,
  },
  props: {
    provider_id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      data: null,
      weight: 0,
      grade: 0,
      items: [],
      homologation_id: null,
      fields: [
        {
          key: "name",
          label: "Indicadores",
          sortable: true,
        },
        {
          key: "weight",
          label: "Peso",
          sortable: true,
        },
        {
          key: "result",
          label: "Indicador",
          sortable: true,
          formatter: (value) => value.toFixed(2),
        },
        {
          key: "score",
          label: "Pontos",
        },
        {
          key: "grade",
          label: "Ponderação",
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.getData();
  },
  methods: {
    getData() {
      api
        .get(
          `/service/${this.service_id}/provider/${this.provider_id}/module/1/result/`
        )
        .then(({ data }) => {
          this.data = data;
          this.weight = data?.total.weight || 0;
          this.grade = data.total.grade || 0;
          this.items = data.indicators;
          this.initPlugin(this.grade.toFixed(1));
        });
    },
    initPlugin(value) {
      let config = {
        needleColor: "black",
        chartWidth: 400,
        needleStartValue: value,
        arcDelimiters: [20, 40, 60, 80],
        arcLabels: [2, 4, 6, 8],
        rangeLabel: ["0", "10"],
        hasNeedle: true,
        arcColors: ["#ff3300", "#f84438", "#e9c31b", "#b7ec82", "#589c14"],
        centralLabel: `${value}`,
        needleValue: value * 10,
      };
      let element = document.querySelector("#gaugeArea");
      GaugeChart.gaugeChart(element, config.chartWidth, config).updateNeedle(
        config.needleValue
      );
    },
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
};
</script>
<style lang="scss">
#fundotercoper {
  max-width: 400px;
}
.gauge {
  width: 400px !important;
}
.fundotercoper {
  position: relative;
  height: 32px;
  border-radius: 50px;
  width: 277px;
  margin: 0 auto;
  margin-top: -40px;

  .cilinder {
    position: absolute;
    top: 0;
    display: flex;
    border-radius: 50%;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .step {
      flex: 1;
      display: flex;
      align-items: center;
      &.step1 {
        background: #ff3300;
        .badge {
          color: white !important;
        }
      }
      &.step2 {
        background: #f84438;
      }
      &.step3 {
        background: #e9c31b;
      }
      &.step4 {
        background: #b7ec82;
      }
      &.step5 {
        background: #589c14;
        .badge {
          color: white !important;
        }
      }
    }
  }
}
</style>
